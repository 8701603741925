.banner {
  background: url('../public/images/header-image1.jpg') center/cover no-repeat;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner img {
  max-width: 100%;
  height: auto;
  /* Optionally, you can set a max-height to limit the image's height */
  /* max-height: 100%; */
}



h1 {
  background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background to the text for better readability */
  padding: 10px;
  border-radius: 5px;
}

.quote-carousel {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .arrow {
    font-size: 48px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .arrow.left {
    left: 0;
    margin-left: 10px; /* Adjust spacing as needed */
  }
  
  .arrow.right {
    right: 0;
    margin-right: 10px; /* Adjust spacing as needed */
  }

  .quote-text {
    font-size: 32px; /* Adjust the font size as needed */
    text-align: center;
    color: darkblue; /* Change the text color to dark blue */
    margin: 0 auto;
    max-width: 80%;
    position: relative;
  }

  cite {
    font-size: 80%;
    float: right;
  }
  
  